.DogsQ ul {
  padding-left: 0;
  list-style: none;
  margin-top: 5px;
}

.DogsQ h3 {
  font-size: 1.4em;
  margin-top: 5px;
  margin-bottom: 0;
}

.DogsQ img {
  max-width: 90vw;
  max-height: 40vh;
}

.DogsQ .disabled {
  color: rgba(0, 0, 0, 0.6);
  background-color: lightgray;
}