.LandingPage h1 {
  font-size: 2.5em
}

.LandingPage img {
  max-height: 50vh;
  max-width: 90vw;
}
.LandingPage p {
  margin: 15px 10px 10px 10px;
}

.LandingPage button {
  margin-bottom: 20px;
}

@media screen and (min-width: 505px) {
  .LandingPage p {
    margin: 40px;
  }
}

@media screen and (min-width: 808px) {
  .LandingPage p {
    margin: 40px 80px;
  }
}